.text-area-journal {
  border: 1px solid var(--light-accent);
  background-color: var(--background-color);
  border-radius: 10px;
  resize: none;
  padding: 10px;
  width: calc(100% - 20px);
  height: 200px;
  font-size: 16px;
}

.journal-select {
  border: 1px solid var(--light-accent);
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 10px;
  width: calc(100%);
  font-size: 16px;
}
.journal-select:active,
.journal-select:focus {
  border: 1px solid var(--accent-color);
}

.journal-label {
  font-size: 16px;
  padding-left: 10px;
}

.journal-checkbox {
  appearance: none;
  border: 1px solid var(--light-accent);
  background-color: var(--background-color);
  border-radius: 4px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.journal-checkbox.checked::before {
  content: "✔";
  color: var(--accent-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.button-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border: none;
  background-color: transparent;
}

.journal-checkbox:checked::before {
  content: "✔";
  color: var(--accent-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}
