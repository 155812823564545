.ai-chat-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100vw;
  height: fit-content;

  position: fixed;
  bottom: 0px;
  left: 0px;
}

.ai-chat-container {
  width: calc(100vw - 24px - 24px);
  max-width: 744px;

  background-color: var(--background-color);
  border: 1px solid var(--light-accent);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 40px;
}

.ai-chat-bottom-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
}
.ai-chat-options-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: fit-content;
}
.ai-chat-send-button {
  background-color: var(--background-secondary);
  color: var(--secondary-text);
  border: none;
  border-radius: 50%;
  aspect-ratio: 1/1;
  cursor: pointer;
}
.ai-chat-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ai-chat-attachment-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
}
/* .ai-chat-attachment {
  display: flex;
  flex-direction: row;
  width: 100%;
} */

.ai-chat-attachment {
  position: relative;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
}

.ai-chat-attachment > button {
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  outline: none;
  background-color: var(--background-color);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ai-chat-attachment:hover > button {
  opacity: 1;
}

@media (max-width: 768px) {
  .ai-chat-container {
    margin-bottom: 12px;
  }
  .ai-chat-attachment > button {
    opacity: 1;
  }
}

.ai-chat-attachment-image {
  border-radius: 8px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.ai-chat-text-area {
  width: calc(100%);
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: var(--background-color);
  resize: none;
  font-size: 16px;
  font-family: "Figtree";
}
.ai-chat-text-area:focus {
  outline: none;
}
.ai-chat-send-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.ai-chat-select-model {
  appearance: none;
  -webkit-appearance: none;
  background-color: var(--background-color);
  background-image: none;
  background: none;
  box-shadow: none;

  border: none;
  color: var(--base-text);
  text-align: right;
  font-family: "Figtree";
}
.ai-chat-select-model.left {
  text-align: left;
}
.ai-chat-select-model.center {
  text-align: center;
}
.ai-chat-recency-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.ai-chat-select-model:focus {
  outline: none;
}

.ai-chat-conversation-container {
  width: calc(100vw - 24px);
  max-width: 744px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 144px;
}
.ai-chat-flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ai-chat-user-message {
  background-color: var(--secondary-white);
  color: var(--secondary-text);
  border: 1px solid var(--light-accent);
  /* background-color: var(--background-secondary);
  color: var(--secondary-text); */
  border-radius: 12px;
  padding: 8px 12px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 70%;
}

.ai-chat-user-message > p {
  color: var(--accent-text);
  /* font-family:  */
  /* color: var(--secondary-text); */
}
.ai-chat-user-message > p {
  margin: 0px;
}

.ai-chat-user-message.img {
  border: none;
  padding: none;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
}
.ai-chat-user-message > img {
  border-radius: 12px;
  max-width: 300px;
  max-height: 200px;
}
.ai-chat-assistant-icon {
  display: flex;
  align-items: start;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  fill: var(--accent-text);
  background-color: var(--background-secondary);
}
.ai-chat-assistant-icon > svg {
  /* transform: translate(-1px, 1px); */
}
.ai-chat-assistant-message {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ai-chat-assistant-message > p {
  margin: 0px;
}
.ai-chat-assistant-message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 12px;
  background-color: var(--background-semi-transparent);
  border-radius: 12px;
}
.ai-chat-user-message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ai-chat-conversation-manager-container {
  position: fixed;
  gap: 12px;
  right: 0px;
  top: 58px;
  width: 0px;
  max-width: calc(100vw - 80px);
  height: 100vh;
  transition: width 0.3s;
  overflow: hidden;
  z-index: 5;
  background-color: var(--background-color);
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.ai-chat-conversation-manager-container.opened {
  width: calc(300px);
  max-width: calc(100vw - 80px);
}
.ai-chat-conversation-manager-sub-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: min(calc(300px - 8px), calc(100vw - 88px));
  padding: 4px;
}

.ai-chat-cm-card {
  border-radius: 4px;
  /* padding: 12px; */
  padding: 8px 12px;
  font-size: 16px;
  gap: 8px;
  display: flex;
  font-weight: 500;
  font-family: "Figtree";
  color: var(--accent-text);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  /* background-color: var(--secondary-white); */
}
.ai-chat-cm-card:hover {
  background-color: var(--light-accent);
}
.ai-chat-cm-card > span {
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ai-chat-cm-ellipsis {
  transition: fill 0.3s;
}
.ai-chat-cm-card > .ai-chat-cm-ellipsis {
  fill: transparent;
}

.ai-chat-cm-card:hover > .ai-chat-cm-ellipsis {
  fill: var(--accent-text);
}

.ai-chat-cm-card.active {
  background-color: var(--background-secondary);
  color: var(--secondary-text);
}
.ai-chat-cm-card.active:hover > .ai-chat-cm-ellipsis {
  fill: var(--secondary-text);
}

.ai-cm-header-title {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: var(--base-text);
  display: flex;
  align-items: center;
  gap: 8px;
}

.ai-chat-sidebar-header-container {
  width: calc(100% - 24px);
  padding: 12px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
