.igen-divider {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.igen-settings-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  /* padding: 20px 20px 0px 20px; */
  height: calc(var(--full-height) - 58px - 20px);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 0px 0px 10px 0px;
  gap: 20px;
  z-index: 5;
}

.ai-nav-container {
  display: flex;
  flex-direction: row;
  height: calc(var(--full-height) - 58px - 20px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0px 0px 10px 0px;
  gap: 15px;
  padding: 20px 20px 0px 10px;
  z-index: 5;
}

.ai-nav-elements {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 35px;
  min-width: 35px;
}

.ai-nav-button {
  width: 100%;
  aspect-ratio: 1/1;
  padding: 7px;
  border-radius: 10px;
  background-color: var(--background-color);
  border: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
}

.ai-nav-button.selected {
  background-color: var(--background-secondary);
  color: var(--secondary-text);
  fill: var(--secondary-text);
  stroke: var(--secondary-text);
}

.ai-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: calc(var(--full-height) - 58px);
}
.igen-canvas {
  min-height: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.igen-display-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  padding: 20px;
  gap: 20px;
}

.image-gen-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  max-height: calc(var(--full-height) - 58px - 20px);
  width: calc(100% - 20px);
  max-width: 100%;
  overflow-y: scroll;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  padding: 10px;
  min-height: calc(var(--full-height) - 58px - 20px);
  background-color: var(--background-color);
}

.ai-pagination-container {
  position: sticky;
  bottom: 10px;
  height: fit-content;
}

.igen-preview-image {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.igen-image-container {
  background-color: var(--secondary-white);
  border-radius: 10px;
  border: 1px solid var(--light-accent);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.igen-image-col-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.igen-image {
  width: 100%;
  height: 100%;
}

.igen-search {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.igen-section {
  display: flex;
  flex-direction: column;
}
.igen-split-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.igen-input-size {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid var(--accent-color);
  background-color: var(--background-color);
  border-radius: 10px;
  font-size: 16px;
}

.igen-input-size::-webkit-inner-spin-button,
.igen-input-size::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  /* appearance: none; */
  margin: 0;
  /* background-color: var(--background-color); */
  border: 1px solid var(--accent-color);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.igen-select {
  border: 1px solid var(--accent-color);
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 10px;
  width: calc(100%);
  font-size: 16px;
}

.text-area-ai {
  border: 1px solid var(--accent-color);
  background-color: var(--background-color);
  border-radius: 10px;
  resize: none;
  padding: 10px;
  width: calc(100% - 20px);
  height: 200px;
  font-size: 16px;
}

/* layers */
.ai-layer-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  user-select: none;
  cursor: pointer;
}

.ai-layer-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ai-layer-title-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.left-bar {
  width: 5px;
  border-radius: 5px;
  height: 100%;
  min-height: 25px;
  background-color: transparent;
}
.left-bar.selected {
  background-color: var(--accent-color);
}

.ai-layer-icon {
  width: 15px;
  height: 15px;
  stroke: var(--background-secondary);
  fill: var(--background-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  /* stroke: var(--secondary-text); */
}

.ai-layer-input {
  border: 0px;
  background-color: transparent;
  font-size: 16px;
}

.ai-layer-input:focus {
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  outline: none;
}

.prompt-modal-container {
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 80vh;
  max-width: 100vw;
  min-width: 70vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.prompt-modal-section {
  grid-column: span 3;
}
/* .ai-layer-input:active {
} */

@media (max-width: 1280px) {
  .prompt-modal-section {
    grid-column: span 4;
  }
  .image-gen-preview-container {
    max-height: none;
    overflow-y: auto;
    flex-direction: column;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    min-height: auto;
  }
  .ai-pagination-container {
    position: absolute;
    top: 0px;
  }
  .igen-preview-image {
    max-width: min(100%, 400px);
  }
  .igen-search {
    max-width: 400px;
  }
}
@media (max-width: 900px) {
  .prompt-modal-container {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
  .prompt-modal-section {
    grid-column: span 6;
  }
  .ai-nav-container {
    flex-direction: column;
    box-shadow: none;
    margin-top: -45px;
    height: fit-content;
  }
  .ai-nav-elements {
    width: 100%;
    flex-direction: row;
  }
  .ai-nav-button {
    max-height: 35px;
    max-width: 35px;
  }
  .igen-divider {
    display: flex;
    flex-direction: column;
  }
  .igen-settings-container {
    box-shadow: none;
    height: fit-content;
    width: 100%;
  }
  .image-gen-preview-container {
    max-height: none;
    overflow-y: auto;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: none;
    justify-content: center;
    align-items: center;
  }
  .igen-preview-image {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    -webkit-touch-callout: none; /* Disable callout, image save panel (iOS) */
    -webkit-user-select: none; /* Disable selection/copy of image (iOS) */
    -moz-user-select: none; /* Disable selection/copy of image (Firefox) */
    -ms-user-select: none; /* Disable selection/copy of image (IE) */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  .ai-flex-center {
    height: 60vh;
    max-height: 60vh;
  }
  .igen-search {
    max-width: 400px;
  }
}

.m-0 {
  margin: 0px;
}
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-new {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-11 {
  grid-column: span 11;
}

.col-12 {
  grid-column: span 12;
}

pre {
  overflow: scroll;
  max-width: 100%;
}
