:root {
  --css-init: true;
  --base-text: #000000;
  --secondary-text: #f2f2f2;
  --accent-text: #363636;
  --accent-text-2: #242424;
  --link-primary: #052858;
  --accent-text-transparent: color-mix(
    in srgb,
    var(--accent-text) 0%,
    transparent
  );
  --base-text-transparent: color-mix(in srgb, var(--base-text) 0%, transparent);
  --link-text: #438bce;
  --light-accent: #c8c8c8;
  --secondary-white: #ededed;
  --background-color: #f2f2f2;
  --background-semi-transparent: color-mix(
    in srgb,
    var(--background-color) 30%,
    transparent
  );
  --background-transparent: color-mix(
    in srgb,
    var(--background-color) 0%,
    transparent
  );
  --background-secondary: #202124;
  --error: #ed2626;
  --error-light: color-mix(in srgb, var(--error) 25%, transparent);
  --accent-color: #88a8ff;
  --full-height: 100vh;
}

/* @supports (height: 100dvh) {
  :root {
    --full-height: 100dvh;
  }
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--accent-text-2);
}

p {
  color: var(--accent-text);
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

/* ------ canvas ------ */

.canvas-render-container {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

.canvas-render {
  image-rendering: crisp-edges;
  position: absolute;
  width: 100vw;
  height: var(--full-height);
}

.canvas-particles {
  width: 100vw;
  height: var(--full-height);
}

/* --------- General --------- */
.pointer-cursor {
  cursor: pointer;
}

/** @font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../public/Fonts/Poppins/Poppins.woff2) format("woff2");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg'
} 
*/

.relative {
  position: relative;
}

.outline-text-shadow {
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
}

.outline-text {
  -webkit-text-stroke-color: #000000ff;
  -webkit-text-stroke-width: 1px;
  color: var(--base-text-transparent);
}

/* Spinner Text CSS */

.spinner-text {
  font-size: 50px;
  text-align: left;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  /* position: absolute; */
  user-select: none;
  /* transition: "color 0.5s, -webkit-text-stroke 0.5s"; */
  font-weight: 800;
  cursor: pointer;
}

.sticky-spinner-container {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: var(--full-height);
  z-index: 2;
  overflow: hidden;
}

.spinner-static-container {
  height: 0px;
  width: 100%;
  position: relative;
  top: 50vh;
  left: 0px;
}

.spinner-anchor-id-item {
  height: 0.1px;
}

.spinner-rotating-wheel {
  position: relative;
  top: 50vh;
  transition: 0.3s;
}

.spinner-sub-text {
  font-size: 18px;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  position: absolute;
  top: 3.2em;
  transition: color 0.3s;
  white-space: nowrap;
}

.spinner-sub-title {
  font-size: 22px;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  position: absolute;
  top: -0.7em;
  transition: color 0.3s;
  white-space: nowrap;
}

.spinner-item-container {
  width: 10px;
  height: fit-content;
  overflow: visible;
  position: absolute;
  /* transition: top 0.5s, left 0.5s, transform 0.5s; */
}

.spinner-container {
  height: var(--full-height);
}

.text-spinner-scroller {
  /* background-color: rgba(0, 0, 0, 0.01); */
  width: 100%;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap;
  /* border: 1px solid blue; */
  /* z-index: 1; */
}

.text-spinner-css-snap {
  display: flex;
  align-items: center;
  height: 50vh;
  scroll-snap-align: center;
  opacity: 0;
}

.text-spinner-scroller::-webkit-scrollbar {
  display: none;
}

.spinner-image {
  width: 100%;
  border-radius: 24px 0px 0px 24px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  object-fit: cover;
  aspect-ratio: 16/12;
}

.spinner-image-container {
  width: 50vw;
  position: absolute;
  right: 0px;
  transform: translate(0, -50%);
  height: fit-content;
}

.spinner-image-container.hidden {
  left: 100vw;
}

.spinner-image-border {
  top: 0px;
  position: absolute;
  transform: translate(-30px, -30px);
  width: 120%;
  height: 100%;
  border: 1px solid #000000;
  z-index: -1;
  border-radius: 40px 0px 0px 40px;
}

@media (max-width: 900px) {
  .spinner-sub-text {
    top: 2.5em;
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .spinner-image-container {
    transition: left 0.75s;
    width: 70vh;
    left: max(calc(50px + 10vw), calc(100vw - 70vh));
    transform: translate(0, -25%);
  }
}

@media (max-width: 420px) {
  .spinner-sub-text {
    top: 2.5em;
    font-size: 15px;
  }
}

/* ------- ArcScrollProgress ------- */

.arc-svg {
  position: absolute;
  top: 0px;
  transition: transform 0.4s;
  width: 100%;
  transform: translate(-50%, -50%);
}

.arc-path {
  transition: transform 0.4s;
  transform-origin: 50% 50%;
}

/* ---------- OutlineContainer ------------ */

.outline-title-left {
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  width: fit-content;
  text-align: left;
  top: calc(-1em - 10px);
  left: 20px;
  /* top: 20px;
  transform: translate(-50%, -50%) rotate(-90deg) translate(-50%, 0%);
  left: calc(-49px + 34px); */
}

.outline-title-right {
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  top: calc(-1em - 10px);
  width: fit-content;
  text-align: left;
  /* transform: translate(-50%, -50%) rotate(90deg) translate(50%, 0%); */
  right: 20px;
}

.outline-title {
  font-size: 40px;
  text-align: left;
  font-weight: 700;
  position: absolute;
  top: calc(-1em - 10px);
  left: 20px;
  transform: none;
}

.outline-title-2 {
  position: absolute;
  text-align: right;
  font-size: 40px;
  font-weight: 700;
  top: calc(-1em - 15px);
}

.outline-tab {
  background-color: var(--background-secondary);
  border-radius: 15px;
  padding: 0px 20px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 82%, 0% 82%);
  color: var(--secondary-text);
}

.outline-tab.outline-tab-2 {
  border-radius: 15px 15px 0px 0px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 93%, 0% 93%);
}

.center-within-margin {
  display: flex;
  max-width: calc(1680px);
  margin: 0px auto;
  flex-direction: column;
  align-items: center;
}

.outline-container-base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outline-container {
  border-radius: 20px;
  border: 1px solid #000000;
  width: calc(100% - 4px - 400px);
  padding: 60px 10px;
  margin: 0px 200px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;
  background-color: color-mix(
    in srgb,
    var(--background-color) 80%,
    transparent
  );
}

.outline-section {
  display: flex;
  width: 65%;
  padding: 0px 98px;
  display: flex;
  flex-direction: column;
}

/* TABLET */
@media (max-width: 1280px) {
  .outline-container {
    flex-direction: column;
    margin: 0px 70px;
    padding: 40px 10px;
    width: calc(100% - 22px - 140px);
  }
  .outline-section {
    width: calc(100% - 140px);
    padding: 0px 60px;
  }
}

/* SMALL */
@media (max-width: 900px) {
  .outline-container {
    flex-direction: column;
    margin: 0px 20px;
    width: calc(100% - 40px - 22px);
    padding: 30px 10px;
  }
  .outline-section {
    width: calc(100% - 40px);
    padding: 0px 20px;
  }
}

/* MOBILE */
@media (max-width: 420px) {
  .outline-container {
    flex-direction: column;
    margin: 0px 0px;
    padding: 20px 10px;
    width: calc(100% - 22px);
  }
  .outline-section {
    width: calc(100% - 40px);
    padding: 0px 20px;
  }
}

/* Experience Block */
.experience-text-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.experience-block-container {
  display: flex;
  flex-direction: column;
  border-left: 1px solid color-mix(in srgb, var(--accent-text) 75%, transparent);
  padding-left: 4px;
}

.experience-title-position-container {
  display: inline-block;
  vertical-align: baseline;
}

.experience-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin: -10px 0px;
  padding: 0px;
  color: var(--accent-text-2);
  width: fit-content;
  display: inline-block;
}

.experience-date {
  font-weight: 400;
  font-size: 13px;
  color: color-mix(in srgb, var(--accent-text) 50%, transparent);
  margin: 0px;
  padding: 0px;
  width: fit-content;
  display: inline-block;
}

.experience-position {
  margin: 0px;
  padding: 0px;
  color: color-mix(in srgb, var(--accent-text) 80%, transparent);
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 5px;
}

.experience-description {
  margin: 0px;
  padding: 0px;
  color: var(--accent-text);
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 900px) {
  .experience-title-position-container {
    display: flex;
    flex-direction: column;
  }
  .experience-date {
    font-size: 14px;
  }
}

/* ---------- Error 404 ------------  */
.error-404-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* ---------- Project ------------  */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.project-title-container {
  width: fit-content;
}

.project-title-container > h2 {
  color: var(--base-text);
  margin: 0px;
  font-family: "Sintony";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.project-title-container > h5 {
  width: calc(100% - 30px);
  text-align: right;
  padding-right: 30px;
  margin: 0px;
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 700;
}

.space {
  height: 50px;
}

.space-2 {
  height: 100px;
}

.space-3 {
  height: 200px;
}

.project-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.project-icon-containers {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.link-no-dec {
  text-decoration: none;
}
.project-link {
  color: var(--link-primary);
  font-size: 20px;
  font-weight: 700;
}

.project-container {
  padding: 0px;
}

.project-container h3 {
  padding: 0px;
  margin: 0px;
  font-size: 32px;
  color: var(--accent-text-2);
  font-weight: 700;
}

.project-img-float-left {
  position: absolute;
  right: -10px;
  max-width: 120%;
  border-radius: 10px;
}

.project-img-float-right {
  position: absolute;
  left: -10px;
  max-width: 120%;
  border-radius: 10px;
}

@media (max-width: 1280px) {
  .project-img-float-left,
  .project-img-float-right {
    position: relative;
    left: 0px;
    max-width: 100%;
  }
  .project-icon-containers {
    position: relative;
    flex-direction: row;
    width: calc(100% - 40px);
    height: fit-content;
    padding: 20px;
  }

  .project-title-container > h2 {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  .project-container h3 {
    font-size: 20px;
  }
  .space {
    height: 30px;
  }
}

.flex {
  display: flex;
}

/* ---------- Navbar -------- */

.nav-container {
  position: fixed;
  top: 0px;
  height: 40px;
  width: calc(100% - 36px);
  background-color: var(--background-secondary);
  z-index: 10;
  box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  padding: 9px 18px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s top;
}

.nav-container-projects {
  justify-content: space-evenly;
}

.nav-hidden {
  top: -80px;
}

.nav-section {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.nav-header {
  color: #c8c8c8;
  font-size: 24px;
  font-weight: 600;
  font-family: "Sintony";
}

.nav-header-2 {
  text-align: center;
}

.nav-text {
  color: #c8c8c8;
  text-align: center;
  font-family: "Sintony";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.nav-text-2 {
  text-align: right;
}

.nav-link {
  cursor: pointer;
}

.nav-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 50% 25%;
}

.small-nav-container {
  width: 40px;
  height: 40px;
  border-radius: 0px 10px 10px 10px;
  background: var(--background-secondary);
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.25);
  z-index: 10;
  position: fixed;
  top: 7px;
  left: 0px;
  transition: width 0.5s, height 0.5s;
  border-top: none;
  border-left: none;
  overflow: hidden;
}

.small-nav-container.opened {
  width: calc(min(100vw, 300px));
  height: var(--full-height);
  justify-content: start;
  align-items: start;
}

.nav-overlay {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: var(--full-height);
  background-color: var(--background-transparent);
  transition: background-color 1s;
  z-index: -500;
}

.nav-overlay.opened {
  background-color: var(--background-semi-transparent);
  z-index: 9;
}

.hamburger-container {
  position: absolute;
  top: 0px;
  left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  cursor: pointer;
}

.nav-item-container {
  position: absolute;
  padding: 20px;
  padding-top: 46px;
  width: calc(min(100vw, 300px) - 40px);
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.nav-item {
  font-family: sintony;
  width: 100%;
  border-bottom: 1px solid var(--light-accent);
  font-size: 32px;
  color: var(--secondary-text);
  cursor: pointer;
}

.nav-item:last-child {
  border-bottom: none;
}

.hamburger {
  position: absolute;
  width: 26px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--background-color);
  padding: 0px;
  margin: 0px;
  transition: transform 0.5s, top 0.5s;
  transform: translate(-50%, -50%);
  top: 30%;
  left: 50%;
}

.hamburger.hidden {
  top: 50%;
}

.hamburger.flipped {
  top: 72%;
}
.hamburger.opened {
  transform: translate(-50%, -50%) rotate(45deg);
  top: 50%;
}
.hamburger.opened.flipped {
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50%;
}

.engine-toggle-container {
  position: absolute;
  left: 0%;
  bottom: 20px;
}

/* ---------- Carousal -------- */

.carousal-container {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  background-color: var(--background-secondary);
}

.carousal-image-container {
  width: fit-content;
  height: 100%;
  position: relative;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousal-image {
  /* height: 100%; */
  max-width: 100vw;
  max-height: 100%;
}

.carousal-next-button {
  position: absolute;
  top: 45%;
  cursor: pointer;
}

.carousal-next-button.right {
  right: 10px;
}
.carousal-next-button.left {
  left: 10px;
}

.carousal-image-container::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: inset 0px 0px 50px 10px var(--background-secondary);
}

.carousal-element {
  position: absolute;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: left 0.5s, opacity 0.5s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousal-video-element {
  max-height: 100%;
  max-width: 100vw;
}

.selection-button {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: color-mix(
    in srgb,
    var(--background-color) 20%,
    transparent
  );
  transition: background-color 0.4s;
  cursor: pointer;
}

.selection-button.opened {
  background-color: var(--background-color);
}

.carousal-button-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  gap: 5px;
  bottom: 5px;
}

@media (max-width: 1280px) {
  .carousal-element {
    border-radius: 10px;
  }
}
@media (max-width: 900px) {
  .carousal-element-container {
    width: 100vw;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}

/* .carousal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.carousal-next-button {
  position: absolute;
  top: 45%;
  cursor: pointer;
}

.carousal-next-button.right {
  right: 10px;
}
.carousal-next-button.left {
  left: 10px;
}

.selection-button.opened {
  background-color: var(--accent-text);
}

.carousal-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  gap: 5px;
}

.carousal-element-container {
  height: 40vw;
}
*/
/* ------- Slider ------- */
.slider-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}
.slider-label {
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins;
  color: var(--base-text);
}
.slider-content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.slider {
  flex: 1;
  height: 4px;
  -webkit-appearance: none;
  background: var(--light-accent);
  border-radius: 2px;
  outline: none;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: var(--accent-color);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.15s ease;
}
.slider::-webkit-slider-thumb:hover {
  background: #0056b3;
}
.slider-value {
  min-width: 40px;
  font-size: 14px;
  color: var(--base-text);
}

/* ------- context menu container -------- */
.context-menu-container {
  position: fixed;
  width: fit-content;
  background-color: var(--background-color);
  border-radius: 12px;
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.25);
  z-index: 10;
  border: 1px solid var(--accent-text-2);
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  min-width: 80px;
}

.context-menu-option {
  font-size: 12px;
  font-weight: 600;
  padding: 4px;
  border-radius: 4px;
  color: var(--base-text);
}

.context-menu-option:hover {
  background-color: var(--background-secondary);
  color: var(--secondary-text);
  cursor: pointer;
}

/* ------- Input ------- */
.portfolio-input {
  border: none;
  min-width: 0px;
  /* width: calc(100% - 8px); */
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  flex: 0 1 100%;
  padding: 5px 0px 5px 5px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.portfolio-input::placeholder {
  color: #808080;
}
.portfolio-input:focus {
  border: none;
  outline: none;
}

.input-label {
  color: var(--base-text);
  font-family: Sintony;
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
}

.portfolio-input-border {
  width: calc(100% - 2px);
  border: 1px solid #969696;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}

.input-container {
  width: 100%;
  margin-top: 10px;
}

.portfolio-input-text-area {
  resize: none;
  font-family: Poppins;
  font-size: 16px;
}

/* ----chips container ---- */
.chips-container {
  min-height: 100px;
}

.chips.portfolio-input-border {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  min-height: 80px;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  padding: 5px;
}

.chips-input {
  border: none;
  overflow: visible;
  width: 0px;
  height: 0px;
  font-size: 20px;
}

.chips-input:focus {
  border: none;
  outline: none;
}

.chip-input-chip {
  padding: 0px 8px;
  border-radius: 15px;
  width: fit-content;
  height: fit-content;
  background-color: var(--secondary-white);
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  align-items: center;
}

.chip-input-chip.editing {
  border: 1px solid var(--base-text);
}

.chip-input-chip > .chip-input-chip {
  background-color: var(--light-accent);
}

.chip-input-delete {
  cursor: pointer;
}

/* ------- Suggestions --------- */
.suggestions-container {
  width: 0px;
  height: 0px;
  z-index: 3;
  overflow: visible;
}

.suggestions-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 120px;
  min-height: 0px;
  max-height: 150px;
  background-color: var(--background-color);
  border-radius: 5px;
  border: 1px solid var(--accent-text-2);
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.suggestions-box::-webkit-scrollbar {
  display: none;
  width: 0px;
}
.suggestions-box::-webkit-scrollbar-track {
  display: none;
  width: 0px;
}

.suggestion-item:hover {
  background-color: var(--secondary-white);
  cursor: pointer;
}

/* ----- collapsable ----- */
.collapsable-container {
  transition: 0.5s;
  overflow: hidden;
}

/* ---- collapsableTitle ---- */

.collapsable-title-container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 0px 10px;
}

.collapsable-title-container-title {
  font-size: 24px;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  color: var(--base-text);
}

.collapsable-seperator {
  width: 100%;
  height: 1px;
  background-color: var(--light-accent);
}

.transform-transition {
  transition: transform 0.3s;
}
/* ----- Toggle ----- */
.toggle-container {
  position: relative;
  border-radius: 50px;
  width: 50px;
  height: 25px;
  background-color: var(--background-color);
}
.toggle-container:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: var(--background-secondary);
  border-radius: 50%;
  transition: 0.3s;
}
.toggle-container.true {
  background-color: var(--accent-color);
}
.true.toggle-container:after {
  left: 27px;
}
.toggle-switch {
  display: none;
}

/* ----- Button ----- */

.button-container {
  padding: 5px 28px;
  border-radius: 5px;
  outline: none;
  border: 0px;
  cursor: pointer;
  background-color: var(--background-secondary);
  font-size: 20px;
  color: var(--background-color);
  font-weight: 400;
  height: fit-content;
  width: fit-content;
  transition: background-color 0.4s;
}

.button-container:hover {
  background-color: color-mix(
    in srgb,
    var(--background-secondary) 80%,
    var(--background-color)
  );
}

.button-disabled {
  cursor: default;
  color: color-mix(in srgb, var(--background-color) 70%, transparent);
  background-color: color-mix(
    in srgb,
    var(--background-secondary) 80%,
    var(--background-color)
  );
}

/* ------ Editor ------ */
.editor-drawer {
  position: fixed;
  top: 0px;
  right: max(-400px, max(-90vw, calc(-100vw + 40px)));
  width: 400px;
  max-width: min(90vw, calc(100vw - 40px));
  height: fit-content;
  max-height: var(--full-height);
  /* overflow: auto; */
  /* overflow-y: scroll; */
  background-color: var(--background-color);
  transition: right 0.5s;
  display: flex;
  flex-direction: column;
  min-height: var(--full-height);
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
.editor-drawer-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  min-height: var(--full-height);
}

.editor-drawer-container::-webkit-scrollbar {
  width: 0px;
}
.editor-drawer-container::-webkit-scrollbar-track {
  width: 0px;
}

.editor-tab-control-container {
  position: relative;
}
.editor-tab-control {
  position: absolute;
  left: -39px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  -webkit-clip-path: inset(-10px 0px -10px -10px);
  clip-path: inset(-10px 0px -10px -10px);
  border-radius: 5px 0px 0px 5px;
}

.editor-drawer::-webkit-scrollbar {
  display: none;
  width: 0px;
}
.editor-drawer::-webkit-scrollbar-track {
  display: none;
  width: 0px;
}

.flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
}
.p-10 {
  padding: 10px;
}

.editor-drawer.opened {
  right: 0px;
}

.editor-collapsable {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor-status {
  display: flex;
  justify-content: space-between;
}

.editor-status > h3 {
  padding: 0px;
  margin: 0px;
  color: var(--base-text);
}

.editor-status > p {
  padding: 0px;
  margin: 0px;
}

/* --- JSON TEXT EDITOR */

.text-area-json.invalid {
  border-color: var(--error);
}
.text-area-json.invalid:focus {
  border-color: var(--error);
  outline: none;
}

/* ----- admin project page ---- */
.admin-project-container {
  display: flex;
  flex-direction: column;
}

.admin-project-container > h3 {
  margin: 0px;
  padding: 0px;
}

.admin-project-item-contanier {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.admin-project-header {
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.admin-project-items-container {
  padding-left: 10px;
}

.admin-project-section-title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins";
  color: var(--accent-text-2);
}
.admin-project-item {
  display: flex;
  justify-content: space-between;
}

/* ------- upload-editor-container ----------*/

.editor-upload-modal-container {
  min-width: 420px;
  padding: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-upload-container {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}
.editor-upload-container.file {
  flex-direction: column;
}

.editor-upload-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.editor-upload-item-container {
  position: relative;
}

.editor-upload-item-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}

.editor-upload-item-actions {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 15px;
  height: 15px;
  transition: 0.3s;
  opacity: 0;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 5px 0px 5px 5px;
}
.editor-upload-item-container:hover {
  .editor-upload-item-actions {
    opacity: 1;
  }
}
.editor-upload {
  width: 115px;
  min-height: 80px;
}
.editor-upload-title-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.upload-editor-preview {
  max-width: 60vw;
  max-height: 70vh;
}

.upload-editor-preview-copy-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 3px;
}

.upload-editor-file-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.upload-editor-file-copy {
  width: 15px;
}

@media (max-width: 420px) {
  .editor-upload-col {
    flex-basis: 50%;
  }
  .editor-upload-item-container {
    width: 100%;
  }
  .editor-upload {
    width: 100%;
  }
  .editor-upload-item-actions {
    opacity: 1;
  }
  .editor-upload-modal-container {
    min-width: 200px;
  }
}

/* ----- Copy -------- */
.copy-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* -------- ScrollTransition -------- */
.scroll-transition-container {
  position: relative;
  width: 100%;
}

/* ------ Modal Container ------ */
.modal-container {
  /* position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%; */
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid var(--background-secondary);
}

.modal-position-container {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: fit-content;
  height: fit-content;
  z-index: 12;
}

.modal-sizing {
  min-width: 200px;
  max-width: calc(100vw - 12px);
  min-height: 140px;
  max-height: var(--full-height);
  width: fit-content;
  height: fit-content;
}

.modal-title {
  max-width: calc(100% - 30px);
  flex-wrap: wrap;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 20px;
  font-weight: 500;
}

.modal-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  z-index: 5;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 3px 0px 3px 3px;
}

.new-project-modal-container {
  min-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

@media (max-width: 420px) {
  .modal-sizing {
    font-size: 20px;
    width: calc(100vw - 10px);
    max-width: calc(100vw - 10px);
    height: var(--full-height);
  }
  .modal-position-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100vw - 10px);
    height: var(--full-height);
    transform: translate(0, 0);
  }
  .modal-container {
    border-radius: 0px;
    border: 0px solid var(--background-secondary);
  }
  .new-project-modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    min-width: 200px;
  }
}

/* --------- drag and drop --------- */
.drag-n-drop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 3px;
}

.file-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  gap: 3px;
  scrollbar-width: 0px;
}

.file-section-container::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.file-section-container::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.file-section-container::-webkit-scrollbar-thumb {
  display: none;
}

.drop-section-container {
  border: 1px dashed var(--accent-text);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  flex-wrap: wrap;
}

.drop-section-dragging {
  border: 1px solid var(--link-text);
  background-color: var(--light-accent);
}

.drag-n-drop-label {
  border: none;
  background-color: none;
  color: var(--link-text);
}

.drag-n-drop-input {
  display: none;
}

.file-container {
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
  background-color: var(--secondary-white);
  display: flex;
  flex-direction: column;
  width: calc(100% - 12px);
}

.file-container.error {
  border: 1px solid var(--error);
}

.file-container.success {
  border: 1px solid var(--accent-color);
}

.file-info-container {
  display: flex;
  height: auto;
  gap: 10px;
}

.file-info-seperator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.file-info-seperator-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-between;
  padding: 2px 0px;
}

.file-info-text {
  color: var(--accent-text);
  font-size: 10px;
}

.file-upload-progress-container {
  display: flex;
  justify-content: start;
  width: 100%;
}

.file-upload-progress {
  background-color: var(--accent-color);
  height: 3px;
  border-radius: 5px;
  transition: width 0.5s, height 0.5s ease-in 0.5s;
}

/* ---- Project Footer ---- */
.project-footer-container {
  width: calc(100vw - 40px);
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.project-footer-item {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
}

.project-footer-item.left {
  justify-content: left;
}
.project-footer-item.right {
  justify-content: right;
}

.project-footer-item > h3 {
  padding: 0px;
  margin: 0px;
  font-size: 30px;
  font-family: "Sintony";
  font-weight: 400;
  color: var(--base-text);
}
.project-footer-item > p {
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-family: "Sintony";
  font-weight: 400;
  color: var(--base-text);
  cursor: pointer;
}

/* ------- Error --------- */
.error-container {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  background-color: var(--error-light);
  color: var(--error);
  font-size: 10px;
}

/* ----- pagination -------- */
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.pagination-button {
  border-radius: 50%;
  padding: 2px;
  width: 15px;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
}

.pagination-button.selected {
  background-color: var(--light-accent);
}

/* ------ Overflow Container ------ */

.overflow-container {
  max-width: 100vw;
  height: fit-content;
  overflow: hidden;
}

/* ------ Thoughts ------ */
.thought-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.thought-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: calc(100vw - 20px);
}

.thought-page-title {
  color: var(--base-text);
  font-weight: 600;
}

.thought-container {
  max-width: min(800px, 100vw - 60px);
  padding: 20px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--accent-text);
  border-radius: 20px;
  background-color: color-mix(
    in srgb,
    var(--background-color) 80%,
    transparent
  );
}

.thought-img {
  width: calc(100% - 0px);
  border-radius: 8px;
}

.thought-text {
  color: #666;
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  white-space: pre-line;
}
.thought-date-container {
  width: 100%;
  margin-top: 20px;
}
.thought-date {
  color: #333;
  font-size: 22px;
}

.footer-spacing {
  height: 50px;
}

/* --------- Languages Section --------- */
.languages-section-container {
  position: relative;
  background-color: var(--background-secondary);
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  /* put the container behind the particles */
  /* z-index: -2; */
  margin-bottom: 100px;
}

.language-banner {
  position: absolute;
  display: flex;
  flex-direction: row;
  word-wrap: nowrap;
  word-break: keep-all;
  width: fit-content;
  gap: 40px;
  animation: banner-shift 60s linear infinite;
  padding: 20px;
}

@keyframes banner-shift {
  0% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(-100%, 0%);
  }
}

.language-item {
  -webkit-text-stroke-color: var(--secondary-text);
  -webkit-text-stroke-width: 1.25px;
  font-size: 45px;
  font-weight: 600;
  color: #00000000;
  /* color: var(--base-text-transparent); */
}
.language-item:hover {
  color: #ffffffff;
}

/* --------- Download Modal --------- */
.download-modal-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 20px);
  padding: 10px;
  padding-top: 20px;
  align-items: center;
}

.download-modal-item {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  color: var(--link-primary);
}

/* ------ Contact Section ------- */

.contact-section-container {
  position: relative;
  margin-top: 50px;
  width: 100%;
  min-height: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -100;
  background-color: var(--background-secondary);
  /* justify-content: center; */
}

.contact-tab-title {
  font-size: 40px;
  text-align: left;
  font-weight: 700;
  position: absolute;
  top: calc(-1em - 10px);
  left: 50%;
  transform: translate(-50%, 0);
}

.contact-title {
  margin: 0px;
  background-color: var(--background-secondary);
  border-radius: 15px;
  padding: 0px 20px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 82%, 0% 82%);
  color: var(--secondary-text);
  word-break: keep-all;
  word-wrap: normal;
  text-wrap: nowrap;
  overflow-wrap: normal;
  min-width: calc(316px -40px);
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.contact-modal-container {
  min-width: calc(420px - 40px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: calc(100% - 40px);
  min-height: 440px;
}

.contact-modal-heading-1 {
  margin: 0px;
  font-size: 30px;
  font-weight: 400;
}

.contact-modal-heading-2 {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: Sintony;
}

.contact-modal-overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color-mix(
    in srgb,
    var(--background-color) 90%,
    transparent
  );
}

.contact-modal-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-modal-icon-container {
  background-color: color-mix(
    in srgb,
    var(--background-color) 90%,
    transparent
  );
}

.contact-error-message {
  font-size: 12px;
  color: var(--error);
}

.contact-svg-container {
  width: 100%;
  height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-horizontal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
}
.contact-svg {
  width: 200px;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .contact-horizontal-container {
    gap: 10px;
  }
}
@media (max-width: 900px) {
  .contact-horizontal-container {
    gap: 10px;
  }
  .contact-svg {
    width: 120px;
  }
}

@media (max-width: 420px) {
  .contact-modal-container {
    min-width: 200px;
    width: calc(100% - 40px);
    max-width: 100%;
    padding: 20px;
  }
  .contact-horizontal-container {
    gap: 0px;
  }
}

/* ------ ParticleCompatibility  Modal */

.compatibility-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: calc(100% - 20px);
  padding: 20px;
}

.compatibility-modal-container > h3 {
  padding: 0px;
  margin: 0px;
}
.compatibility-modal-container > span {
  text-align: center;
}
/* ------ no particle intro ------- */

.no-particle-intro-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: -60px;
  flex-direction: column;
}
.no-particle-top-intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.25em;
}
.no-particle-intro-sub-heading {
  margin-top: -15px;
}

.image-clip-container {
  animation: bg-image-1 10s ease-in-out infinite;
}

@keyframes bg-image-1 {
  0% {
    background-position-y: 0px;
  }
  50% {
    background-position-y: 15px;
  }
  100% {
    background-position-y: -0px;
  }
}

.image-clip-text {
  font-weight: 700;
  color: transparent;
}

/* ------ loading ------ */
/* .loading-overlay {
  width: 100vw;
  height: var(--full-height);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
.loading-overlay.fade-out {
  animation: fade-out 0.5s;
  pointer-events: none;
}

.loading-container {
  opacity: 0;
  animation: fade-in 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
} */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #202124;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ------ basic ------ */
.padding-bottom-20 {
  padding-bottom: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}

/* ---------- Screen Sizing -------- */
@media (min-width: 1200px) {
  .is-tablet:not(.is-desktop) {
    display: none;
  }
  .is-small:not(.is-desktop) {
    display: none;
  }
  .is-mobile:not(.is-desktop) {
    display: none;
  }
}

@media (max-width: 1280px) and (min-width: 900px) {
  .is-desktop:not(.is-tablet) {
    display: none;
  }
  .is-small:not(.is-tablet) {
    display: none;
  }
  .is-mobile:not(.is-tablet) {
    display: none;
  }
}

@media (max-width: 900px) and (min-width: 420px) {
  .is-desktop:not(.is-small) {
    display: none;
  }
  .is-tablet:not(.is-small) {
    display: none;
  }
  .is-mobile:not(.is-small) {
    display: none;
  }
}

@media (max-width: 420px) {
  .is-desktop:not(.is-mobile) {
    display: none;
  }
  .is-tablet:not(.is-mobile) {
    display: none;
  }
  .is-small:not(.is-mobile) {
    display: none;
  }
}
